import axios from "axios"

const getOrdersData = async (serviceId, payload) => {
    let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-order-items", payload)
    return res.data
}

const getOrderLineInfo =  async (serviceId,payload) => {
    let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-order-items", payload)
    return res.data
}

const uploadFeedFile =  async (payload) => {
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/inputFeedFile", payload)
    return res.data
}

const orderStatusMaster =  async (serviceId, payload) => {
    let res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-order-status-master", payload)
    return res.data
}

const getProcessTemplate = async (serviceId, payload) => {
    let res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-processing-update-template", payload)
    return res.data
}

const getTrackingInfo = async(param) =>{
    let res = await axios.get(process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/get-tracking-info/" + param, {}).catch(()=>{
        return {}
    })
    return res.data
}

const service =  {
    getOrdersData,
    getOrderLineInfo,
    uploadFeedFile,
    orderStatusMaster,
    getProcessTemplate,
    getTrackingInfo
}

export default service