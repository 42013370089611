import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { plazaServices } from './plazaReportService'
import GeneralBarChart from './GeneralBarChart'
import * as XLSX from "xlsx";
import { Calendar } from 'primereact/calendar';
import moment from 'moment'

const WeeklyReports = () => {
  const [masterData, setMasterData] = useState([])
  const serviceId = localStorage.getItem("serviceId")
  const [selectedDate, setSelectedDate] = useState(new Date(moment().subtract(1, "days")))
  const [plazaSpv, setPlazaSpv] = useState({})
  const onDownload = async (item) => {
    const round = (arr) => {
      arr = arr.map((item) => {
        return Math.round(item)
      })
      return arr
    }
    const data = [
      [item.plaza, ...item.category],
      ["Traffic In Thousands", ...round(item.traffic)],
      ["Revenue In Lakhs", ...round(item.revenue)],
      ["Pos Revenue In Lakhs", ...round(item.pos)],
      ["Tickets Sale In Lakhs", ...round(item.tickets)],
      ["Traffic in 2023-24", ...round(item.prevTraffic)],
      ["Revenue in 2023-24", ...round(item.prevRevenue)],
      ["Variation In Revenue", ...round(item.varRevenue)],
      ["Variation In Traffic", ...round(item.varRevenue)]
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "TrafficAndRevenueData.xlsx");
  };

  const onDownloadAll = () => {
    const aggregateBySpv = (data, plazaToSpvMap) => {
      const spvData = {};

      data.forEach((item) => {
        const spv = plazaToSpvMap[item.plaza];
        if (!spv) {
          console.warn(`No SPV mapping found for plaza: ${item.plaza}`);
          return;
        }

        if (!spvData[spv]) {
          spvData[spv] = {
            spv,
            category: [...item.category],
            traffic: [...item.traffic],
            revenue: [...item.revenue],
            pos: [...item.pos],
            tickets: [...item.tickets],
            prevTraffic: [...item.prevTraffic],
            prevRevenue: [...item.prevRevenue],
            varTraffic: [...item.varTraffic],
            varRevenue: [...item.varRevenue],
          };
        } else {
          // Aggregate data for existing SPV entry
          spvData[spv].traffic = spvData[spv].traffic.map((val, idx) => val + (item.traffic[idx] || 0));
          spvData[spv].revenue = spvData[spv].revenue.map((val, idx) => val + (item.revenue[idx] || 0));
          spvData[spv].pos = spvData[spv].pos.map((val, idx) => val + (item.pos[idx] || 0));
          spvData[spv].tickets = spvData[spv].tickets.map((val, idx) => val + (item.tickets[idx] || 0));
          spvData[spv].prevTraffic = spvData[spv].prevTraffic.map((val, idx) => val + (item.prevTraffic[idx] || 0));
          spvData[spv].prevRevenue = spvData[spv].prevRevenue.map((val, idx) => val + (item.prevRevenue[idx] || 0));
          spvData[spv].varTraffic = spvData[spv].varTraffic.map((val, idx) => val + (item.varTraffic[idx] || 0));
          spvData[spv].varRevenue = spvData[spv].varRevenue.map((val, idx) => val + (item.varRevenue[idx] || 0));
        }
      });

      return Object.values(spvData);
    };
    const dataArray = aggregateBySpv([...masterData], plazaSpv)

    if (!dataArray || dataArray.length === 0) {
      console.error("No data available to download.");
      return;
    }
    const headers = [
      "Plaza",
      "Date",
      "Traffic In Thousands",
      "Revenue In Lakhs",
      "POS Revenue In Lakhs",
      "Tickets Sale In Lakhs",
      "Traffic in 2023-24",
      "Revenue in 2023-24",
      "Variation In Revenue",
      "Variation In Traffic"
    ];
    const excelData = [headers];
    dataArray.forEach((item) => {
      const numRows = item.category.length;

      for (let i = 0; i < numRows; i++) {
        excelData.push([
          item.spv,
          item.category[i],
          Math.round(item.traffic[i]),
          Math.round(item.revenue[i]),
          Math.round(item.pos[i]),
          Math.round(item.tickets[i]),
          Math.round(item.prevTraffic[i]),
          Math.round(item.prevRevenue[i]),
          Math.round(item.varRevenue[i]),
          Math.round(item.varTraffic[i])
        ]);
      }
    });
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "TrafficAndRevenueData");
    XLSX.writeFile(workbook, "TrafficAndRevenueData.xlsx");
  };
  const handleDateChange = async (e) => {
    setSelectedDate(e.target.value)
  };

  const StyledCalendar = () => {
    return (
      <div className="w-full">
        <label className=" relabel">Timeline:</label>
        <Calendar
          id="range"
          value={selectedDate}
          maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
          placeholder="Select Date"
          onChange={handleDateChange}
          showIcon
          readOnlyInput
          className="p-calendar flex align-items-center bg-white"
          style={{
            borderRadius: '17px',
            border: '2px solid #0C6291',
            width: "100%"
          }}
          dateFormat="dd-mm-yy"
        />
      </div>
    );
  };
  const convertValueType = (item) => {
    return Number.isInteger(item) ? item : Math.round(item)
  }
  const getPreviousYearDates = (start, end, yearsAgo) => {
    return {
      startDate: moment(start).subtract(yearsAgo, 'years').format('YYYY-MM-DD'),
      endDate: moment(end).subtract(yearsAgo, 'years').format('YYYY-MM-DD'),
    };
  };
  const makePayload = (type, project, match, yearsAgo = 0, all) => {
    const { startDate: adjustedStartDate, endDate: adjustedEndDate } = getPreviousYearDates(moment(selectedDate).subtract(6, 'days').format('YYYY-MM-DD'), moment(selectedDate).subtract(0, 'days').format('YYYY-MM-DD'), yearsAgo);
    return {
      operation: "aggregate",
      aggregate: [
        {
          $match: {
            date: {
              "$gte": adjustedStartDate,
              "$lte": adjustedEndDate,
            },
            ...match
          },
        },
        {
          $group: {
            _id: {
              date: "$date",
              plazaCode: all ?? "$plazacode"
            },
            [project]: { $sum: `$${type}` },
          },
        },
        {
          $project: {
            [project]: 1,
            _id: 1,
          },
        },
        {
          $sort: {
            "_id.plazaCode": -1,
            "_id.date": -1,
          }
        }
      ],
    };
  }
  const ShowTable = ({ item }) => {
    item.pos = item.pos.map((item) => {
      return item * 0.00001
    })
    item.tickets = item.tickets.map((item) => {
      return item * 0.00001
    })
    return (
      <div style={{ overflowX: "auto" }} className='card generalCard'>
        <div className='col-12 grid justify-content-end lg:ml-4 '> <Button onClick={() => { onDownload(item) }} style={{ backgroundColor: "white", color: "#2A9D8F", border: "1px solid #2A9D8F", fontStyle: "Inter" }} className=''>Download</Button></div>
        <table className="p-datatable p-column-header-content" style={{ width: "100%", borderCollapse: "collapse", textAlign: "center", }}>
          <thead>
            <tr style={{ backgroundColor: "white", fontWeight: "bold", borderRadius: "6px" }}>
              <th style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "6px" }}>{item.plaza}</th>
              {item.category.map((categoryItem, index) => (
                <th key={ Math.random()} style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {categoryItem}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="p-datatable-tbody">
            <tr>
              <td style={{ border: "1px solid #ccc", padding: "10px", fontWeight: "bold" }}>Traffic In Thousands</td>
              {item.traffic.map((trafficItem, index) => (
                <td key={ Math.random()} style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {convertValueType(trafficItem)}
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ border: "1px solid #ccc", padding: "10px", fontWeight: "bold" }}>Revenue In Lakhs</td>
              {item.revenue.map((revenueItem, index) => (
                <td key={ Math.random()} style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {convertValueType(revenueItem)}
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ border: "1px solid #ccc", padding: "10px", fontWeight: "bold" }}>Pos Revenue In Lakhs</td>
              {item.pos.map((posItem, index) => (
                <td key={ Math.random()} style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {convertValueType(posItem)}
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ border: "1px solid #ccc", padding: "10px", fontWeight: "bold" }}>Tickets Sale Revenue In Lakhs</td>
              {item.tickets.map((ticketItem, index) => (
                <td key={ Math.random()} style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {convertValueType(ticketItem)}
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ border: "1px solid #ccc", padding: "10px", fontWeight: "bold" }}>Traffic in {moment().year() - 1}-{moment().year()}</td>
              {item.prevTraffic.map((ticketItem, index) => (
                <td key={ Math.random()} style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {convertValueType(ticketItem)}
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ border: "1px solid #ccc", padding: "10px", fontWeight: "bold" }}>Revenue in {moment().year() - 1}-{moment().year()}</td>
              {item.prevRevenue.map((ticketItem, index) => (
                <td key={ Math.random()} style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {convertValueType(ticketItem)}
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ border: "1px solid #ccc", padding: "10px", fontWeight: "bold" }}>Variation In Revenue</td>
              {item.varRevenue.map((ticketItem, index) => (
                <td key={ Math.random()} style={{ border: "1px solid #ccc", padding: "10px", color: `${ticketItem > 0 ? "green" : "red"}` }}>
                  {convertValueType(ticketItem)}%
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ border: "1px solid #ccc", padding: "10px", fontWeight: "bold", }}>Variation In Traffic</td>
              {item.varTraffic.map((ticketItem, index) => (
                <td key={ Math.random()} style={{ border: "1px solid #ccc", padding: "10px", color: `${ticketItem > 0 ? "green" : "red"}` }}>
                  {convertValueType(ticketItem)}%
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

    );
  }

  useEffect(() => {
    const trafficPayload = makePayload("count", "traffic", {}, 0);
    const revPayload = makePayload("totalAmount", "revenue", {}, 0);
    const posPayload = makePayload("totalAmount", "revenue", { "tablename": "pos" }, 0);
    const prevTrafficPayload = makePayload("count", "traffic", {}, 1);
    const prevRevPayload = makePayload("totalAmount", "revenue", {}, 1);
    Promise.all([plazaServices.general(serviceId, trafficPayload, "nq-total_traffic_daily"),
    plazaServices.general(serviceId, revPayload, "nq-totalRevenue_by_plaza_date_daily"),
    plazaServices.apiGeneralFunction(serviceId, posPayload, "nq-totalRevenue_by_plaza_date_daily")
      , plazaServices.general(serviceId, prevTrafficPayload, "nq-total_traffic_daily"),
    plazaServices.general(serviceId, prevRevPayload, "nq-totalRevenue_by_plaza_date_daily")]).then((res) => {
      let arr = {}
      let Arr = {}
      let posMap = {};
      for (let i = 0; i < res[2].length; i++) {
        posMap[`${res[2][i]?._id?.plazaCode}-${res[2][i]?._id?.date}`] = res[2][i]?.revenue
      }
      for (let i = 0; i < res[0].length; i++) {
        let plaza = res[0][i]?._id?.plazaCode;
        let vtraffic = ((res[0][i].traffic - res[3][i].traffic) / res[3][i].traffic) * 100
        let vrevenue = ((res[1][i].revenue - res[4][i].revenue) / res[4][i].revenue) * 100
        if (arr[plaza]) {
          arr[plaza].category.push(moment(res[0][i]?._id.date).format('MMMM Do YY'));
          arr[plaza].traffic.push(res[0][i].traffic * 0.001);
          arr[plaza].revenue.push(res[1][i].revenue * 0.00001);
          arr[plaza].pos.push(posMap[`${res[0][i]?._id?.plazaCode}-${res[0][i]?._id?.date}`] || 0);
          arr[plaza].tickets.push(res[1][i].revenue - posMap[`${res[0][i]?._id?.plazaCode}-${res[0][i]?._id?.date}`] || res[1][i].revenue)
          arr[plaza].prevTraffic.push(res[3][i].traffic * 0.001);
          arr[plaza].prevRevenue.push(res[4][i].revenue * 0.00001);
          arr[plaza].varTraffic.push(vtraffic)
          arr[plaza].varRevenue.push(vrevenue)
        }
        else {
          arr[plaza] = {
            plaza: plaza,
            category: [moment(res[0][i]?._id.date).format('MMMM Do YY')],
            traffic: [res[0][i].traffic * 0.001],
            revenue: [res[1][i].revenue * 0.00001],
            pos: [posMap[`${res[0][i]?._id?.plazaCode}-${res[0][i]?._id?.date}`] || 0],
            tickets: [res[1][i].revenue - posMap[`${res[0][i]?._id?.plazaCode}-${res[0][i]?._id?.date}`] || res[1][i].revenue],
            prevTraffic: [res[3][i].traffic * 0.001],
            prevRevenue: [res[4][i].revenue * 0.00001],
            varTraffic: [vtraffic],
            varRevenue: [vrevenue]
          }
        }
      }
      const trafficPayload = makePayload("count", "traffic", {}, 0, 1);
      const revPayload = makePayload("totalAmount", "revenue", {}, 0, 1);
      const posPayload = makePayload("totalAmount", "revenue", { "tablename": "pos" }, 0, 1);
      const prevTrafficPayload = makePayload("count", "traffic", {}, 1, 1);
      const prevRevPayload = makePayload("totalAmount", "revenue", {}, 1, 1);

      Promise.all([
        plazaServices.general(serviceId, trafficPayload, "nq-total_traffic_daily"),
        plazaServices.general(serviceId, revPayload, "nq-totalRevenue_by_plaza_date_daily"),
        plazaServices.apiGeneralFunction(serviceId, posPayload, "nq-totalRevenue_by_plaza_date_daily"),
        plazaServices.general(serviceId, prevTrafficPayload, "nq-total_traffic_daily"),
        plazaServices.general(serviceId, prevRevPayload, "nq-totalRevenue_by_plaza_date_daily"),
      ]).then((res) => {
        let posMap = {};
        for (let i = 0; i < res[2].length; i++) {
          const key = `${res[2][i]?._id?.date}`;
          posMap[key] = res[2][i]?.revenue || 0;
        }
        for (let i = 0; i < res[0].length; i++) {
          const date = moment(res[0][i]?._id?.date).format('MMMM Do YY');
          const traffic = res[0][i].traffic * 0.001;
          const revenue = res[1][i].revenue * 0.00001;
          const pos = posMap[date] || 0;
          const tickets = res[1][i].revenue - pos || res[1][i].revenue;
          const prevTraffic = res[3][i]?.traffic * 0.001 || 0;
          const prevRevenue = res[4][i]?.revenue * 0.00001 || 0;

          const vtraffic = prevTraffic ? ((traffic - prevTraffic) / prevTraffic) * 100 : 0;
          const vrevenue = prevRevenue ? ((revenue - prevRevenue) / prevRevenue) * 100 : 0;

          if (Arr["total"]) {
            Arr["total"].traffic.push(traffic);
            Arr["total"].revenue.push(revenue);
            Arr["total"].pos.push(pos);
            Arr["total"].tickets.push(tickets);
            Arr["total"].prevTraffic.push(prevTraffic);
            Arr["total"].prevRevenue.push(prevRevenue);
            Arr["total"].varTraffic.push(vtraffic);
            Arr["total"].varRevenue.push(vrevenue);
            Arr["total"].category.push(date)
          } else {
            Arr["total"] = {
              "plaza": "All",
              category: [date],
              traffic: [traffic],
              revenue: [revenue],
              pos: [pos],
              tickets: [tickets],
              prevTraffic: [prevTraffic],
              prevRevenue: [prevRevenue],
              varTraffic: [vtraffic],
              varRevenue: [vrevenue],
            };
          }
        }
        arr = { ...Arr, ...arr }
        const resultArray = Object.keys(arr).map((key) => {
          const { plaza, category, traffic, revenue, pos, tickets, prevTraffic, prevRevenue, varTraffic, varRevenue } = arr[key];
          return { plaza, category, traffic, revenue, pos, tickets, prevTraffic, prevRevenue, varTraffic, varRevenue };
        });
        setMasterData(resultArray)
      });

    })
    Promise.all([plazaServices.general(serviceId, {}, "nq-plaza-master")]).then((res) => {
      let plazaSpvMap = { "All": "All" }
      res[0].map((item) => {
        plazaSpvMap[item.PlazaCode] = item.SPV;
      })
      setPlazaSpv(plazaSpvMap)

    })

  }, [selectedDate])
  return (
    <div className='grid col-12 justify-content-center m-0'>
      <div className=" col-12">
        <div className=''>
          <div className='pt-2 bg-white card generalCard grid align-items-center' >
            <h3 className="chartHeading mt-2">{"Weekly Reports"}</h3>
            <Button onClick={onDownloadAll} style={{ backgroundColor: "white", color: "#2A9D8F", border: "1px solid #2A9D8F", fontStyle: "Inter" }} className=' ml-2'>Download ALL</Button>
            <div className="col-12 flex justify-content-start flex-wrap" style={{ gap: "1rem" }}>
              <div className="lg:flex-grow-0 flex-grow-1" style={{ minWidth: "14rem" }}>
                {StyledCalendar()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        masterData.map((item) => {
          let chart = {
            category: item.category,
            data: [
              {
                name: "Traffic In Thousands",
                data: item.traffic.map((item) => { return convertValueType(item) }),
                color: "rgb(0,0,128)",
                type: "column",
              },
              {
                name: "Revenue In Lakhs",
                data: item.revenue.map((item) => { return convertValueType(item) }),
                color: "rgb(253,125,40)",
                type: "line",
              }
            ]
          }
          return <div className='m-0  grid justify-content-center col-12'>
            <div className='card mt-2 generalCard grid  col-12 justify-content-between' style={{ flexGrow: 1 }}>
              <div className='lg:col-6 col-12 m-0 p-0' style={{ display: "flex", alignItems: "stretch", flexWrap: "wrap" }}>
                <div className='col-12 flex justify-content-between'>
                  <p className='chartHeading' style={{ fontSize: "1rem", color: "black" }}>Traffic and Revenue for {item.plaza} Plaza</p>
                </div>
                <GeneralBarChart chartData={chart} legend={true} height={380} />
              </div>
              <div className='lg:col-6 col-12 m-0 p-0'>
                {<ShowTable item={item} />}
              </div>
            </div>
          </div>
        })
      }
    </div>
  )
}

export default WeeklyReports