import React, { useState, useEffect, useRef, } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { plazaServices } from './plazaReportService';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment';
import * as XLSX from "xlsx";
import { Calendar } from 'primereact/calendar';
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputSwitch } from 'primereact/inputswitch';
import GeneralBarChart from './GeneralBarChart';
import service from '../../service/apiService';
import TollRevenueReport from './TollReport';
import ExemptionReports from './ExemptionReports';
const DownloadButton = (typeCont, field) => {
    const onDownload = () => {
        let data = [];
        let filename = "reports.xlsx";
        typeCont.map((item) => {
            let temp = {};
            if (item) {
                for (const key in item) {
                    if (key !== "_id") temp[key] = item[key];
                }
            }
            data.push(temp);
        })
        let ws = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, filename);
    };

    return (
        <div className="download-box mr-4 ml-3">
            <Button className=" p-button-text " onClick={() => onDownload()}>
                <i className="pi pi-download ml-2" style={{ color: '#0C6291' }}></i>
            </Button>
        </div>
    );
};
let headCollection = {
    countHead: ["Year", "Vehicles", "Growth"],
    amountHead: ["Year", "Amount(₹)", "Growth"],
}



const GeneralStack =({chartData})=>{
    const [state, setState] = useState(null)
    useEffect(() => {
        if (chartData) {
            setState(chartData);
        }
    }, [chartData]); // Re-run effect when chartData changes

    if (!state) {
        return <div>Loading chart...</div>; // Optional loading state
    }

    return <HighchartsReact highcharts={Highcharts} options={state} />;

}


const getGreetingMessage = () => {
    const currentHour = moment().hour();

    if (currentHour >= 5 && currentHour < 12) {
        return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
        return "Good Afternoon";
    } else if (currentHour >= 17 && currentHour < 21) {
        return "Good Evening";
    } else {
        return "Good Night";
    }
};

const amountHeader = (
    <ColumnGroup className="bg-cyan-500 text-white card ">
        <Row>
            <Column header={"Year"} className={`bg-white `} style={{ marginRight: '110px' }}></Column>
            <Column header={"Amount(₹)"} className={`bg-white `} style={{ marginRight: '110px' }}></Column>
            <Column header={"Growth"} className={`bg-white `} style={{ marginRight: '110px' }}></Column>
        </Row>
    </ColumnGroup>
);
const vehtHeader = (
    <ColumnGroup className="bg-cyan-500 text-white card ">
        <Row>
            <Column header={"Year"} className={`bg-white `} style={{ marginRight: '110px' }}></Column>
            <Column header={"Vehicles"} className={`bg-white `} style={{ marginRight: '110px' }}></Column>
            <Column header={"Growth"} className={`bg-white `} style={{ marginRight: '110px' }}></Column>
        </Row>
    </ColumnGroup>
);

const PlazaDashBoard = () => {
    const [traffic, setTraffic] = useState([])
    const [selectedYear, setSelectedYear] = useState(null);
    const [vehType, setVehType] = useState(null);
    const [plazaType, setPlazaType] = useState(null);
    const [monthList, setMonthList] = useState(null);
    const [month, selectedMonth] = useState(0);
    const [revenue, setRevenue] = useState();
    const [exempt, setExempt] = useState()
    const [loss, setLoss] = useState()
    const [field, setSelectedField] = useState(null);
    const [yesrev, setYesRev] = useState(0);
    const [yestraffic, setYesTraffic] = useState(0);
    const [yesRevLoss, setYesRevLoss] = useState(0);
    const [yesExempt, setYesExempt] = useState(0);
    const [plazaList, setPlazaList] = useState([]);
    const [selectedPlaza, setSelectedPlaza] = useState(null)
    const [dateRange, setDateRange] = useState([new Date(moment().subtract(1, "days").format('YYYY-MM-DD')), new Date(moment().subtract(1, "days").format('YYYY-MM-DD'))]);
    const [trtablHeader, setTRTableHead] = useState(null)
    const [votablHeader, setVoTableHead] = useState(null)
    const [retablHeader, setReTableHead] = useState(null)
    const [extablHeader, setExTableHead] = useState(null)

    const [revb, setRevB] = useState('Yearly')
    const [trafficb, setTrafficB] = useState('Yearly')
    const [lossb, setLossB] = useState('Yearly')
    const [exemptb, setExemptB] = useState('Yearly')
    const [typeBar, setTypeBar] = useState(null);
    const [plazaBar, setPlazaBar] = useState(null);
    const [plazadet, setPlazaDet] = useState(false);
    const [typedet, setTypeDet] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [detailcomp, setDeatilComp] = useState("")
    const [plazaCodes, setPlazaCodes] = useState([])
    const [ETC, setEtc] = useState(null)
    const [spv, setSpv] = useState(null);
    const [spvList, setSpvList] = useState([]);
    const [showtype, setShowType] = useState("SPV")
    const [stack, setStack] = useState(null);
    const [dailytype, setDailyType] = useState([]);
    const [spvMap, setSpvMap] = useState({})
    const name = localStorage.getItem("username")
    const thingNames = useSelector(state => state.thingNameContainer);
    let arr = thingNames.length > 0 ? thingNames[thingNames.length - 1] : [];
    const serviceId = localStorage.getItem("serviceId");
    const valueTypes = useRef([])
    const [selectedvalueType, setSelectedValueType] = useState(1)
    const colorMap = {
        "Total Traffic":"#0C6291",
        "Total Revenue":"#2A9D8F",
        "Total ETC Revenue":"#FFBA47"
    }
    
    const showGraph = (data, plaza) => {
        if (plaza === "plaza") return data;
        const ans = {}
        if (data) {
            data.map((item) => {
                for (const key in spvMap) {
                    if (spvMap[key].includes(item.plaza) && !ans[key]) {
                        ans[key] = item.total;
                    }
                    else if (spvMap[key].includes(item.plaza) && ans[key]) {
                        ans[key] = ans[key] + item.total;
                    }
                }
            })

            let ansarray = [];
            for (const key in ans) {
                let temp = {
                    plaza: key,
                    total: ans[key]
                }
                ansarray.push(temp)
            }
            return ansarray;
        }
        return plazaType


    }
    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            if (
                (newWidth < 761 && screenWidth >= 761) || 
                (newWidth > 800 && screenWidth < 800)
            ) {
                setScreenWidth(newWidth);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);
    

    useEffect(() => {
        let formattedStartDate = null;
        let formattedEndDate = null;

        if (dateRange[0] !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedStartDate = moment(dateRange[0]).startOf('day').toDate();
        }

        if (dateRange[1] !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedEndDate = moment(dateRange[1]).endOf('day').toDate();
        }

        const temp = [formattedStartDate, formattedEndDate];
        setDateRange(temp);
        service.httpDBService({}, "nextqore", "nq-value-types").then((res) => {
            if (res) {
                valueTypes.current = res
                let initialValueType = res[0].value
                setSelectedValueType(initialValueType)
            }
        })

    }, [])

    const showCharts = (charts, width,type,color) => {
        if(charts && charts.series &&charts.series[0].data  &&type!=="stack"){
            let chartData = {
                "category": charts.xAxis.categories,
                data: [{
                    name:"",
                    data:charts.series[0].data.map((item)=>{return Number(convertValueType(item))}),
                    color:color    
                }],
                text:charts.xAxis?.title?.text
            };
            return <GeneralBarChart chartData={chartData} selectedvalueType={selectedvalueType} legend = {false}/>
        }
        else if(charts && charts.series &&charts.series[0].data  &&type==="stack"){
            let newSeries=[]
            charts.series.map((item)=>{
               let temp =item.data.map((key)=>{
                return Number(convertValueType(key))
               })
               newSeries.push({
                name:item.name,
                data:temp
               })
            })
            let newChartData = {...charts,series:newSeries};
            return <GeneralStack chartData={newChartData}/>
        }
        
    }
    const changeGrap = () => {
        if (showtype === "SPV") {
            setShowType("plaza")
            let ans = showGraph(plazaType, "plaza");
            setPlazaBar(barCharType(ans, "Plaza Wise Distribution", colorPalettes.color2));
        }
        else {
            setShowType("SPV")
            let ans = showGraph(plazaType, "SPV");
            setPlazaBar(barCharType(ans, "Spv Wise Distribution", colorPalettes.color2));
        }

    }

    let filter = (name, setFunc, type) => {
        return (<div className="mr-4">
            <Button className='bg-white mb-3' link="true" onClick={() => {
                setFunc(true)
            }}>
                <span style={{ color: '#0C6291' }}>{"Show Details"}</span>
                <i className="pi pi-angle-down ml-3" style={{ color: '#0C6291' }}></i>
            </Button>
        </div>)
    }
    //To help us in apii call
    const totalMap = {
        "nq-nontollable_vrn_cch_daily": "count",
        "nq-total_traffic_daily": "count",
        "nq-totalRevenueLoss_daily": "loss",
        "nq-totalRevenue_by_plaza_date_daily": "totalAmount"

    }

    const headerMap = {
        "nq-nontollable_vrn_cch_daily": "Exemptions",
        "nq-total_traffic_daily": "Traffic",
        "nq-totalRevenueLoss_daily": "Voilations",
        "nq-totalRevenue_by_plaza_date_daily": "Revenue"

    }

    const typeMap = {
        "nq-nontollable_vrn_cch_daily": "tablename",
        "nq-total_traffic_daily": "mvc",
        "nq-totalRevenueLoss_daily": "mvc",
        "nq-totalRevenue_by_plaza_date_daily": "mvc"

    }

    const generalFunc = async (collection, payload, setFuc, setHead, head) => {
        setFuc(null)
        let data = await plazaServices.general(serviceId, payload, collection);
        if (head === "Week") {
            data = processWeeklyData(data);
        }
        if (data.length > 0) {
            data[0].growth = "";
            for (let i = 1; i < data.length; i++) {
                if (data[i].count) data[i].growth = (((data[i].count - data[i - 1].count) / data[i - 1].count) * 100).toFixed(2) + "%";
                else data[i].growth = (((data[i].total - data[i - 1].total) / data[i - 1].total) * 100).toFixed(2) + "%";

            }
        }

        setFuc(data);
        let dynamicHead = [];
        if (collection === "nq-totalRevenueLoss_daily" || collection === "nq-totalRevenue_by_plaza_date_daily") dynamicHead = headCollection.amountHead
        else dynamicHead = headCollection.countHead
        dynamicHead[0] = head;
        let tempHead = <ColumnGroup className="bg-cyan-500 text-white card ">
            <Row>
                {
                    dynamicHead.map((item) => {
                        return <Column header={item.toUpperCase()} key={item} className={`bg-white `} style={{ marginRight: '110px' }}></Column>
                    })
                }
            </Row>
        </ColumnGroup>
        setHead(tempHead)
    }

    const SPVSelector = () => {
        const customTemplate = (option) => (
            <div className="flex align-items-center">
                <i className="pi pi-map-marker" style={{ color: '#0C6291' }}></i>
                <span>{option.label}</span>
            </div>
        );

        return (
            <div className="w-full">
                <label className="relabel">Select SPV:</label>
                <Dropdown
                    id="spv"
                    value={spv}
                    options={spvList}
                    onChange={(e) => {
                        setSpv(e.value);
                        setMonthList(null);
                        if (!e.value) setSelectedPlaza(null);
                        setShowType("SPV")
                    }}
                    placeholder={
                        <span className="flex align-items-center" style={{ color: '#0C6291' }}>
                            <i className="pi pi-map-marker" style={{ marginRight: '8px' }}></i>
                            Select a SPV
                        </span>
                    }
                    itemTemplate={customTemplate}
                    className="general-bar-chart-filter flex align-items-center  p-0"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',
                        width: "100%"
                    }}
                />
            </div>
        );
    };


    const TollSelector = () => {
        const customTemplate = (option) => (
            <div className="flex align-items-center">
                <i className="pi pi-map-marker" style={{ color: '#0C6291' }}></i>
                <span>{option.label}</span>
            </div>
        );

        return (
            <div className="w-full">
                <div className="relabel">Toll Plaza:</div>
                <Dropdown
                    id="tollDropdown"
                    value={selectedPlaza}
                    options={plazaList}
                    onChange={(e) => {
                        setSelectedPlaza(e.value);
                        setMonthList(null)
                        setShowType("SPV")
                    }}
                    placeholder={
                        <span className="flex align-items-center" style={{ color: '#0C6291' }}>
                            <i className="pi pi-map-marker" style={{ marginRight: '8px' }}></i>
                            Select a Toll
                        </span>
                    }
                    itemTemplate={customTemplate}
                    className="general-bar-chart-filter flex align-items-center "
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',
                        width: "100%"
                    }}
                />
            </div>
        );
    };
    const handleDateChange = (e) => {
        const [startDate, endDate] = e.value;
        let formattedStartDate = null;
        let formattedEndDate = null;

        if (startDate !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedStartDate = moment(startDate).startOf('day').toDate();
        }

        if (endDate !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedEndDate = moment(endDate).endOf('day').toDate();
        }
        const temp = [formattedStartDate, formattedEndDate];
        setDateRange(temp);
    };

    const barCharType = (res, Field, colour) => {
        let slabs = [];
        let empCount = [];
        res.map((item) => {
            if (item.plaza) slabs.push(item.plaza);
            else slabs.push(item.type);
            if (item.count !== null) empCount.push(item.total);
        })
        const slabwiseCount = {
            chart: {
                type: "column",
            },
            title: {
                text: ` `,
                align: "center",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: slabs,
                title: {
                    text: Field,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: "",
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.0f} </b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    pointWidth: 15,
                    showInLegend: false,
                    name: "",
                    data: empCount,
                    color: colour,

                }

            ],
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
                    }
                }
            }
        };
        return slabwiseCount;


    }

    const stackCharType = (res, Field) => {
        const datesSet = new Set();
        const typesSet = new Set();
        res.forEach(entry => {
            datesSet.add(entry.date);
            typesSet.add(entry.type);
        });
        let dates = [...datesSet].sort();
        const types = [...typesSet].sort();
        const result = types.map(type => ({
            name: type,
            data: dates.map(date => {
                const entry = res.find(d => d.date === date && d.type === type);
                return entry ? entry.total : 0;
            })
        }));

        let xAxis = "day wise";
        // Assuming 'result' is an array of series objects, each representing a different type

        // Calculate total sum for each category (date)
        const stackTotals = dates.map(date => {
            return result.reduce((sum, series) => {
                const point = series.data.find(dataPoint => dataPoint.category === date);
                return sum + (point ? point.y : 0);
            }, 0);
        });

        dates = dates.map((item) => {
            return moment(item, 'YYYY-MM-DD,h:mm').format('Do, MMM');
        })
        const slabwiseCount = {
            chart: {
                type: "column",
            },
            title: {
                text: ` `,
                align: "center",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: dates,
                title: {
                    text: xAxis,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: "",
                },
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.y < 1  && this.y!==0? this.y.toFixed(2) : this.y;
                        },
                    },
                },
                series: {
                    stackLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.total < 1  && this.y!==0 ? this.total.toFixed(2) : this.total;
                        },
                        style: {
                            fontWeight: 'bold',
                        },
                    },
                },
            },            
            series: result,
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
                    }
                }
            }
        };

        return slabwiseCount;
    }

    const StyledCalendar = () => {
        return (
            <div className="w-full">
                <label className=" relabel">Timeline:</label>
                <Calendar
                    id="range"
                    value={dateRange}
                    placeholder="Select Date"
                    onChange={handleDateChange}
                    selectionMode="range"
                    showIcon
                    readOnlyInput
                    className="general-bar-chart-filter flex align-items-center "
                    dateFormat="dd-mm-yy"
                    style={{
                        borderRadius: '17px',
                        border: '2px solid #0C6291',
                        width: "100%"
                    }}
                />
            </div>
        );
    };

    const monthMap = {
        0: selectedYear,
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec"
    };

    const commaAdder = (row) => {

        let show
        if (!row.total) show = row.count;
        else show = row.total;
        if (!show) return 0;
        show = convertValueType(show)
        return <span className='white-space-nowrap' style={{}}>{show.toLocaleString() + " " + selectedvalueType["abbr"]}</span>;
    }

    const convertValueType = (value) => {
        if (!value) return 0
        value = Number(value) * selectedvalueType["mult"]
        if (!Number.isInteger(value)){
            let res = 0;
            let temp = value
            while(Math.trunc(temp)===Math.trunc(value)){
                res++;
               temp = temp*Math.pow(10, res)
            }
            value=value.toFixed(res)
        } 
        return value
    }
    const processWeeklyData = (data) => {

        const weeksMap = {};
        data.forEach(item => {
            const weekStart = moment(item.week).startOf('week').format('MMM Do ');
            const weekEnd = moment(item.week).endOf('week').format('MMM Do ');

            const weekKey = `${weekStart} - ${weekEnd}`;
            if (!weeksMap[weekKey]) {
                weeksMap[weekKey] = {
                    year: weekKey,
                    dates: [],
                    total: 0
                };
            }

            weeksMap[weekKey].dates.push(item.week);
            weeksMap[weekKey].total += item.total;
            weeksMap[weekKey].field = item.field

        });
        return Object.values(weeksMap);
    };

    const aggregateData = (Month, groupByField, dailyStack) => {
        let type = groupByField === 'plazacode' ? 'plaza' : 'type';
        let matchStage = {
            year: selectedYear
        };

        if (Month !== 0) {
            matchStage.month = Month;
        }
        if (selectedPlaza) {
            matchStage["plazacode"] = selectedPlaza
        }
        else matchStage["plazacode"] = { $in: plazaCodes }
        if (ETC === "etc" && field === "nq-totalRevenue_by_plaza_date_daily") matchStage["tablename"] = { $in: ["cch1", "cch2", 'pos'] }
        let basePayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: matchStage
                },
                {
                    $group: {
                        _id: `$${groupByField}`,
                        total: { $sum: `$${totalMap[field]}` }
                    }
                },
                {
                    $project: {
                        [type]: "$_id",
                        total: 1
                    }
                },
                { $sort: { date: 1 } }
            ]
        };
        if (dailyStack === true) {
            basePayload = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: matchStage
                    },
                    {
                        $group: {
                            _id: {
                                date: "$date",
                                type: `$${typeMap[field]}`
                            },
                            total: { $sum: `$${totalMap[field]}` }
                        }
                    },
                    {
                        $project: {
                            _id: 0,
                            date: "$_id.date",
                            total: 1,
                            type: "$_id.type"
                        }
                    },
                    { $sort: { date: 1 } }
                ]
            };
        }
        return plazaServices.general(serviceId, basePayload, field);
    };

    const monthWiseBreakUp = async (Month) => {
        selectedMonth(Month);
        setShowType("SPV")
        let typeHeader = field === "nq-nontollable_vrn_cch_daily" ? "System Wise Distribution" : "Vehicle Wise Distribution";
        const vehTypePromise = aggregateData(Month, typeMap[field]);
        const plazaTypePromise = aggregateData(Month, 'plazacode');
        const dailyStackType = aggregateData(Month, '', true);
        const [vehTypeData, plazaTypeData, dailyStack] = await Promise.all([vehTypePromise, plazaTypePromise, dailyStackType]);
        setVehType(vehTypeData);
        setDailyType(dailyStack);
        setStack(stackCharType(dailyStack, field))
        let ans = showGraph(plazaTypeData, "SPV")
        setPlazaType(plazaTypeData);

        setTypeBar(barCharType(vehTypeData, typeHeader, colorPalettes.color1));
        setPlazaBar(barCharType(ans, "SPV Wise Distribution", colorPalettes.color2));
    };

    const fetchData = async (period, row) => {
        setSelectedField(row.field);
        setSelectedYear(row.year)
        let dateMatch = {}
        if (row.year && period !== 'weekly') {
            dateMatch = {
                year: row.year,
            };
        }
        if (row.type && row.type === "etc" && row.field === "nq-totalRevenue_by_plaza_date_daily") {
            setEtc("etc")
            dateMatch.tablename = { $in: ["cch1", "cch2", 'pos'] }
        }
        else setEtc(null)

        if (period === 'monthly') {
            dateMatch.month = row.month;
        }

        if (period === 'weekly') {
            dateMatch.date = {
                $gte: row.dates[0],
                $lte: row.dates[row.dates.length - 1]
            };
        }

        if (selectedPlaza) {
            dateMatch.plazacode = selectedPlaza;
        }
        else {
            dateMatch.plazacode = { $in: plazaCodes }
        }

        const baseAggregate = [
            { $match: dateMatch }
        ];

        const plazaAggregate = [
            ...baseAggregate,
            {
                $group: {
                    _id: "$plazacode",
                    total: { $sum: `$${totalMap[row.field]}` }
                }
            },
            {
                $project: {
                    plaza: "$_id",
                    total: 1
                }
            }
        ];

        const typeAggregate = [
            ...baseAggregate,
            {
                $group: {
                    _id: `$${typeMap[row.field]}`,
                    total: { $sum: `$${totalMap[row.field]}` }
                }
            },
            {
                $project: {
                    type: "$_id",
                    total: 1
                }
            }
        ];

        const monthAggregate = period === 'yearly' ? [
            ...baseAggregate,
            {
                $group: {
                    _id: "$month",
                    total: { $sum: `$${totalMap[row.field]}` }
                }
            },
            {
                $project: {
                    month: "$_id",
                    total: 1
                }
            },
            { $sort: { month: 1 } }
        ] : null;

        const dailyAggregate = period !== 'yearly' ? [
            ...baseAggregate,
            {
                $group: {
                    _id: {
                        date: "$date",
                        type: `$${typeMap[row.field]}`
                    },
                    total: { $sum: `$${totalMap[row.field]}` }
                }
            },
            {
                $project: {
                    _id: 0,
                    date: "$_id.date",
                    total: 1,
                    type: "$_id.type"
                }
            },
            { $sort: { date: 1 } }
        ] : null;
        if (period !== 'yearly') {
            let res = await plazaServices.general(serviceId, { operation: "aggregate", aggregate: dailyAggregate }, row.field)
            setDailyType(res);
            setStack(stackCharType(res, row.field))
        }

        try {
            const requests = [
                plazaServices.general(serviceId, { operation: "aggregate", aggregate: plazaAggregate }, row.field),
                plazaServices.general(serviceId, { operation: "aggregate", aggregate: typeAggregate }, row.field),
            ];

            if (monthAggregate) {
                requests.unshift(plazaServices.general(serviceId, { operation: "aggregate", aggregate: monthAggregate }, row.field));
            }

            const results = await Promise.all(requests);
            let typeHeader = row.field === "nq-nontollable_vrn_cch_daily" ? "System Wise Distribution" : "Vehicle Wise Distribution";
            if (monthAggregate) {
                const [monthData, plazaData, typeData] = results;
                let Total = monthData.reduce((acc, curr) => acc + curr.total, 0);
                setMonthList([{ month: 0, total: Total }, ...monthData]);
                setPlazaType(plazaData);
                setVehType(typeData);
                setTypeBar(barCharType(typeData, typeHeader, colorPalettes.color1));
                let ans = showGraph(plazaData, "SPV")
                setPlazaBar(barCharType(ans, "Spv Wise Distribution", colorPalettes.color2));
            } else {
                const [plazaData, typeData] = results;
                setPlazaType(plazaData);
                setVehType(typeData);
                setTypeBar(barCharType(typeData, typeHeader, colorPalettes.color1));
                setMonthList([]);
                let ans = showGraph(plazaData, "SPV")
                setPlazaBar(barCharType(ans, "Spv Wise Distribution", colorPalettes.color2));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {

        let filterPayload = {
            filter: {
            },
            projection: { "PlazaCode": 1, "PlazaName": 1, "zip": 1, "SPV": 1 }
        }
        if (spv !== null) filterPayload.filter["SPV"] = spv
        Promise.all([plazaServices.general(serviceId, filterPayload, "nq-plaza-master")]).then((res) => {

            let temp = [];
            let plazaCode = [];
            res[0].map((item) => {
                let tempObj;
                if (arr.length > 0) {
                    if (arr.includes(item.PlazaCode)) {
                        tempObj = {
                            label: `${item.PlazaName}_${item.PlazaCode}`, value: item.PlazaCode, SPV: item.SPV
                        }
                        temp.push(tempObj)
                        plazaCode.push(item.PlazaCode);
                    }
                }
                else {
                    tempObj = {
                        label: `${item.PlazaName}_${item.PlazaCode}`, value: item.PlazaCode, SPV: item.SPV
                    }
                    temp.push(tempObj)
                    plazaCode.push(item.PlazaCode);
                }
            })
            setPlazaCodes(plazaCode)
            let spvmap = {};
            if (spv === null) {
                const map = {};
                let spvArray = [];
                spvArray[0] = { label: "ALL", value: null }
                temp.map((item) => {
                    if (!map[item.SPV]) {
                        let newTemp = {
                            label: item.SPV,
                            value: item.SPV
                        }
                        map[item.SPV] = true
                        spvArray.push(newTemp);
                        spvmap[item.SPV] = [item.value]
                    }
                    else spvmap[item.SPV].push(item.value)

                })
                setSpvMap(spvmap);
                setSpvList(spvArray)

            }

            temp = [{
                label: "All Tolls", value: null
            }, ...temp]
            setPlazaList(temp);
        })

    }, [spv])

    useEffect(() => {
        let matchObj = {}
        let payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: null,
                        count: { $sum: "$count" },
                    }

                },
                {
                    $project: {
                        count: 1
                    }
                }
            ]
        };
        let revPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: null,
                        count: { $sum: "$totalAmount" },
                    }

                },
                {
                    $project: {
                        count: 1
                    }
                },
                { $sort: { year: 1 } }
            ]
        };
        let lossPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        tablename: { $in: ["cch1", "cch2", 'pos'] }
                    }
                },
                {
                    $group: {
                        _id: null,
                        count: { $sum: "$totalAmount" },
                    }

                },
                {
                    $project: {
                        count: 1
                    }
                }
            ]
        };
        if (selectedPlaza) {
            matchObj = {
                $match: {
                    date: {
                        $gte: moment(dateRange[0]).format('YYYY-MM-DD'),
                        $lte: moment(dateRange[1]).format('YYYY-MM-DD'),
                    },
                    plazacode: selectedPlaza
                }
            }

        } else {
            matchObj = {
                $match: {
                    date: {
                        $gte: moment(dateRange[0]).format('YYYY-MM-DD'),
                        $lte: moment(dateRange[1]).format('YYYY-MM-DD'),
                    },
                    plazacode: {
                        $in: plazaCodes
                    }
                }
            }

        }
        payload.aggregate = [matchObj, ...payload.aggregate]
        revPayload.aggregate = [matchObj, ...revPayload.aggregate]
        lossPayload.aggregate = [matchObj, ...lossPayload.aggregate]
        if (dateRange[0] && dateRange[1] && plazaCodes.length > 0) {
            Promise.all([plazaServices.general(serviceId, payload, "nq-total_traffic_daily"), plazaServices.general(serviceId, revPayload, "nq-totalRevenue_by_plaza_date_daily"), plazaServices.general(serviceId, payload, "nq-nontollable_vrn_cch_daily"), plazaServices.general(serviceId, lossPayload, "nq-totalRevenue_by_plaza_date_daily")]).then((res) => {
                if (res[0].length) setYesTraffic(res[0][0].count);
                else setYesTraffic(0)
                if (res[1].length) setYesRev(res[1][0].count);
                else setYesRev(0);
                if (res[2].length) setYesExempt(res[2][0].count);
                else setYesExempt(0)
                if (res[3].length) setYesRevLoss(res[3][0].count)
                else setYesRevLoss(0)


            })

        }

    }, [selectedPlaza, dateRange[1], plazaCodes.length, spv])


    useEffect(() => {
        if (traffic) {
            setTraffic(null)
            setRevenue(null);
            setExempt(null)
            setLoss(null)
        }
        if (trtablHeader) {
            setTRTableHead(vehtHeader);
            setVoTableHead(vehType);
            setReTableHead(amountHeader);
            setExTableHead(amountHeader)
        }
        let trafficPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$year",
                        count: { $sum: "$count" },
                    }

                },
                {
                    $project: {
                        year: "$_id",
                        count: 1,
                        field: "nq-total_traffic_daily",
                        growth: "0"
                    }
                },
                { $sort: { year: 1 } }
            ]
        };
        let nonTollPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$year",
                        count: { $sum: "$count" },
                    }

                },
                {
                    $project: {
                        year: "$_id",
                        count: 1,
                        field: "nq-nontollable_vrn_cch_daily",
                        growth: "0"
                    }
                },
                { $sort: { year: 1 } }
            ]
        };
        let revPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$year",
                        count: { $sum: "$totalAmount" },
                    }

                },
                {
                    $project: {
                        year: "$_id",
                        count: 1,
                        field: "nq-totalRevenue_by_plaza_date_daily",
                        growth: "0"
                    }
                },
                { $sort: { year: 1 } }
            ]
        };
        let lossPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        tablename: { $in: ["cch1", "cch2", 'pos'] }
                    }
                },
                {
                    $group: {
                        _id: "$year",
                        count: { $sum: "$totalAmount" },
                    }

                },
                {
                    $project: {
                        year: "$_id",
                        count: 1,
                        field: "nq-totalRevenue_by_plaza_date_daily",
                        growth: "0",
                        type: "etc"
                    }
                },
                { $sort: { year: 1 } }
            ]
        };
        let matchObj = {}
        if (selectedPlaza) {
            matchObj = {
                $match: {
                    "plazacode": selectedPlaza
                }
            }
            setLossB("Yearly")
            setRevB("Yearly")
            setTrafficB("Yearly")
            setExemptB("Yearly")
        } else {
            matchObj = {
                $match: {
                    "plazacode": {
                        $in: plazaCodes
                    }
                }
            }
            setLossB("Yearly")
            setRevB("Yearly")
            setTrafficB("Yearly")
            setExemptB("Yearly")

        }
        trafficPayload.aggregate = [matchObj, ...trafficPayload.aggregate];
        nonTollPayload.aggregate = [matchObj, ...nonTollPayload.aggregate]
        revPayload.aggregate = [matchObj, ...revPayload.aggregate]
        lossPayload.aggregate = [matchObj, ...lossPayload.aggregate]
        if (plazaCodes.length > 0) {
            Promise.all([plazaServices.general(serviceId, trafficPayload, "nq-total_traffic_daily"), plazaServices.general(serviceId, revPayload, "nq-totalRevenue_by_plaza_date_daily"), plazaServices.general(serviceId, nonTollPayload, "nq-nontollable_vrn_cch_daily"), plazaServices.general(serviceId, lossPayload, "nq-totalRevenue_by_plaza_date_daily")]).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    if (res[i].length) res[i][0].growth = 0;
                    for (let j = 1; j < res[i].length; j++) {
                        let growthPercentage = res[i][j].count - res[i][j - 1].count;
                        growthPercentage = (growthPercentage / res[i][j - 1].count) * 100;
                        if (growthPercentage !== Infinity) res[i][j].growth = growthPercentage.toFixed(2) + " %";
                        else res[i][j].growth = 0 + " %";

                    }
                }
                setTraffic(res[0])
                setRevenue(res[1]);
                setExempt(res[2])
                setLoss(res[3])
            })
        }
    }, [selectedPlaza, plazaCodes.length])


    const yearShow = (row,field) => {
        if (row.dates) return <button className='button' style={{color:colorMap[field]}} onClick={(e) => { fetchData('weekly', row) }}>{row.year}</button>
        if (row.month) return <button className='button' style={{color:colorMap[field]}} onClick={(e) => { fetchData("monthly", row) }}>{monthMap[row.month]}</button>
        return <button className='button'style={{color:colorMap[field]}} onClick={(e) => { fetchData("yearly", row) }}>{row.year}</button>
        //return<><Button className='bg-white text-cyan-800 text-base underline'>{row.year}</Button>
        //</> 
    }

    const MonthlyDetails = (left) => {
        if (!monthList) return null;
        let daily = [];
        if (dailytype.length) {
            let dateMap = {};
            for (let i = 0; i < dailytype.length; i++) {
                if (!dateMap[dailytype[i].date]) dateMap[dailytype[i].date] = dailytype[i].total;
                else dateMap[dailytype[i].date] = dateMap[dailytype[i].date] + dailytype[i].total
            }

            for (const key in dateMap) {
                let temp;
                if (dateMap[key] > 0) {
                    temp = {
                        date: key,
                        total: dateMap[key]
                    }
                    daily.push(temp);
                }
            }

        }
        const typeHeader = (
            <ColumnGroup className="bg-cyan-500 text-white card monthly-details">
                <Row>
                    <Column header={field === "nq-nontollable_vrn_cch_daily" ? "System wise Distribution" : "Vehicle Class"} className="bg-cyan-500 text-white"></Column>
                    <Column header={field === "nq-totalRevenue_by_plaza_date_daily" || field === "nq-totalRevenueLoss_daily" ? "Amount(₹)" : "Number of Vehicles"} className="bg-cyan-500 text-white"></Column>
                </Row>
            </ColumnGroup>
        );

        const dailyTypeHeader = (
            <ColumnGroup className="bg-cyan-500 text-white card monthly-details">
                <Row>
                    <Column header="date" className="bg-cyan-500 text-white"></Column>
                    <Column header={field === "nq-totalRevenue_by_plaza_date_daily" || field === "nq-totalRevenueLoss_daily" ? "Amount(₹)" : "Number of Vehicles"} className="bg-cyan-500 text-white"></Column>
                </Row>
            </ColumnGroup>
        );

        const plazaHeader = (
            <ColumnGroup className="bg-cyan-500 text-white card monthly-details">
                <Row>
                    <Column header={showtype === "SPV" ? "SPV" : "Plaza"} className="bg-cyan-500 text-white"></Column>
                    <Column header={field === "nq-totalRevenue_by_plaza_date_daily" || field === "nq-totalRevenueLoss_daily" ? "Amount(₹)" : "Number of Vehicles"} className="bg-cyan-500 text-white"></Column>
                </Row>
            </ColumnGroup>
        );

        return (
            <div className={`col-12 mt-4 bg-white details-box  `}>
                <div className="flex align-items-center justify-content-between chartHeading col-12" style={{backgroundColor:"#12C3FF",color:"white",margin:"0px",borderRadius:"12px"}}>
                    <div className="text-xl line-height-4 font-medium ml-3" >
                        {field ? ETC === "etc" ? "Total ETC Revenue" : `Total ${headerMap[field]} ` : null}{month ? ` for ${monthMap[month]}` : null} for the year {selectedYear}
                    </div>
                    <div className="flex align-items-center mt-2">
                        <div className="close-color text-lg" style={{color:"white"}} >Close</div>
                        <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" className="ml-1  close-color hover:text-gray-900" style={{color:"white",backgroundColor:"#12C3FF"}} onClick={() => {
                            setMonthList(null);
                            setPlazaType(null);
                            selectedMonth(0);
                            setPlazaDet(false);
                            setTypeDet(false);
                        }} />
                    </div>
                </div>

                {monthList.length ? (
                    <div className="flex flex-wrap newborder mt-3">
                        {monthList.map((item) => (
                            <div key={item.month} className={`flex flex-column p-2 mr-4 ${month === item.month ? 'bg-blue-700 text-white border-rad' : ''}`}>
                                <Button
                                    className={`button-selected ${month === item.month ? 'bg-blue-700 text-white' : 'bg-white text-black-alpha-90'}`}
                                    onClick={() => { monthWiseBreakUp(item.month); }}
                                >
                                    <div className="button-content">
                                        {monthMap[item.month]}
                                        <div className={`${month === item.month ? 'text-selected' : 'bg-white text-black-alpha-90'}`}>
                                            {field === "nq-totalRevenueLoss_daily" || field === "nq-totalRevenue_by_plaza_date_daily" ? `₹ ${Number(convertValueType(item.total)) + selectedvalueType["abbr"]}` : Number(convertValueType(item.total)) + selectedvalueType["abbr"]}
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        ))}
                    </div>
                ) : null}

                {!monthList.length || parseInt(month) !== 0 ? <TabView>
                    <TabPanel header="Daily">
                        <div className={`card col-12  generalCard`}>
                            {showCharts(stack, "col-12","stack")}
                            {!typedet ? filter("Show details According to Vehicle Class In Tabular Form", setTypeDet) : (
                                <div className="col-12">
                                    <div className="flex justify-content-between align-items-center">
                                        {DownloadButton(daily, field)}
                                        <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" className="bg-white close-color hover:text-gray-900" onClick={() => setTypeDet(false)} />
                                    </div>
                                    <DataTable headerColumnGroup={dailyTypeHeader} className="datatable-box card w-full" value={daily} responsiveLayout="scroll" rows={10} paginator>
                                        <Column className="bg-white font-medium p-1 surface-300 bg-gray-200" style={{ color: "#5E6771" }} body={(item) => { return moment(item.date, 'YYYY-MM-DD,h:mm').format('Do, MMM') }}></Column>
                                        <Column className="bg-white font-medium p-1 surface-300 bg-gray-200" style={{ color: "#5E6771" }} body={commaAdder}></Column>
                                    </DataTable>
                                </div>
                            )}
                        </div>

                    </TabPanel>
                    <TabPanel header="Vehicle Class">
                        <div className={`card col-12  generalCard`}>
                            {showCharts(typeBar, "col-12","","#0C6291")}
                            {!typedet ? filter("Show details According to Vehicle Class In Tabular Form", setTypeDet) : (
                                <div className="col-12">
                                    <div className="flex justify-content-between align-items-center">
                                        {DownloadButton(vehType, field)}
                                        <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" className="bg-white close-color hover:text-gray-900" onClick={() => setTypeDet(false)} />
                                    </div>
                                    <DataTable headerColumnGroup={typeHeader} className="datatable-box card w-full" value={vehType} responsiveLayout="scroll" rows={10} paginator>
                                        <Column className="bg-white font-medium p-1 surface-300 bg-gray-200" style={{ color: "#5E6771" }} field="type"></Column>
                                        <Column className="bg-white font-medium p-1 surface-300 bg-gray-200" style={{ color: "#5E6771" }} body={commaAdder}></Column>
                                    </DataTable>
                                </div>
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel header={showtype === "SPV" ? "SPV Wise" : "PlazaWise"}>
                        <div className={`card col-12  flex flex-column generalCard`}>
                            <div className="text-base mt-2 mb-4 flex justify-content-end" style={{ paddingLeft: "1.5rem", float: "right" }}>
                                SPV
                                <InputSwitch checked={showtype === "plaza"} style={{ marginLeft: "0.8rem", marginRight: "0.8rem" }} onChange={(e) => { changeGrap() }} />
                                Plaza
                            </div>
                            {showCharts(plazaBar,"","","#2A9D8F")}
                            {!plazadet ? filter("Show details According to Plaza In Tabular Form", setPlazaDet, "plaza") : (
                                <div className="col-12">
                                    <div className="flex justify-content-between align-items-center">
                                        {DownloadButton(plazaType, field)}
                                        <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" className="bg-white close-color hover:text-gray-900" onClick={() => setPlazaDet(false)} />
                                    </div>
                                    <DataTable headerColumnGroup={plazaHeader} className="datatable-box card w-full" value={showGraph(plazaType, showtype)} responsiveLayout="scroll" rows={10} paginator>
                                        <Column className="bg-white font-medium p-1 bg-gray-200" style={{ color: "#5E6771", backgroundColor: "#FAFAFA" }} field="plaza"></Column>
                                        <Column className="bg-white font-medium p-1 bg-gray-200" style={{ color: "#5E6771", backgroundColor: "#FAFAFA" }} body={commaAdder}></Column>
                                    </DataTable>
                                </div>
                            )}
                        </div>
                    </TabPanel>
                </TabView> : <div className="flex flex-column md:flex-row col-12 mt-4 justify-content-between align-items-center" style={{flexGrow:1}}>
                    <div className={`card generalCard col-12 md:col-6 mb-4 md:mb-0 `}>
                        {showCharts(typeBar,"", "bar","#0C6291")}
                        {!typedet ? filter("Show details According to Vehicle Class In Tabular Form", setTypeDet) : (
                            <div className="col-12">
                                <div className="flex justify-content-between align-items-center">
                                    {DownloadButton(vehType, field)}
                                    <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" className="bg-white close-color hover:text-gray-900" onClick={() => setTypeDet(false)} />
                                </div>
                                <DataTable headerColumnGroup={typeHeader} className="datatable-box card w-full" value={vehType} responsiveLayout="scroll" rows={10} paginator>
                                    <Column className="bg-white font-medium p-1 surface-300 bg-gray-200" style={{ color: "#5E6771" }} field="type"></Column>
                                    <Column className="bg-white font-medium p-1 surface-300 bg-gray-200" style={{ color: "#5E6771" }} body={commaAdder}></Column>
                                </DataTable>
                            </div>
                        )}
                    </div>

                    <div className={`card generalCard flex flex-column col-12 md:col-6 `}>
                        <div className="text-base mt-2 mb-4 flex justify-content-end items-center">
                            <span style={{ paddingLeft: "1.5rem" }}>SPV</span>
                            <InputSwitch
                                checked={showtype === "plaza"}
                                style={{ marginLeft: "0.8rem", marginRight: "0.8rem" }}
                                onChange={changeGrap}
                            />
                            <span>Plaza</span>
                        </div>

                        {showCharts(plazaBar,"","","#2A9D8F")}
                        {!plazadet ? filter("Show details According to Plaza In Tabular Form", setPlazaDet, "plaza") : (
                            <div className="col-12">
                                <div className="flex justify-content-between align-items-center">
                                    {DownloadButton(plazaType, field)}
                                    <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" className="bg-white close-color hover:text-gray-900" onClick={() => setPlazaDet(false)} />
                                </div>
                                <DataTable headerColumnGroup={plazaHeader} className="datatable-box card w-full" value={showGraph(plazaType, showtype)} responsiveLayout="scroll" rows={10} paginator>
                                    <Column className="bg-white font-medium p-1 bg-gray-200" style={{ color: "#5E6771", backgroundColor: "#FAFAFA" }} field="plaza"></Column>
                                    <Column className="bg-white font-medium p-1 bg-gray-200" style={{ color: "#5E6771", backgroundColor: "#FAFAFA" }} body={commaAdder}></Column>
                                </DataTable>
                            </div>
                        )}
                    </div>
                </div>}


            </div>
        );
    };

    const yesterdayCard = (field, borderStyle, totalField, dailyTotal, left, classNames) => {
        return (
            <div className={`${classNames} `}>
                <div className={`relianceCard  ${left} col-12 p-4`} style={{ backgroundColor: `${colorMap[field]}` }}>
                    <div className='flex-colum border-color'>
                        <div className=' white-space-nowrap' style={{ color: "white" }}>
                            <p className='m-0 relianceFont'>{totalField !== "Amount" ? `${Number(convertValueType(dailyTotal))} ${selectedvalueType["abbr"]}` : `₹ ${Number(convertValueType(dailyTotal)) + " " + selectedvalueType["abbr"]}`}</p>
                        </div>
                        <div className='flex align-items-center justify-content-between flex-wrap p-1 mt-1'>
                            <div className='p-flex p-flex-column p-text-left p-text-dark relianceSubfont ' style={{ fontFamily: 'Inter, sans-serif', fontSize: '1.3rem', fontWeight: '600', lineHeight: '1.25rem', letterSpacing: '1px', color: "white" }}>
                                {field}
                            </div>
                        </div>
                    </div>
                </div></div>
        );
    };

    const reportShow = (item) => {
        return <div className="flex col-12 lg:col-6 flex-column">
            <div className="styled-div px-2 p-d-flex p-jc-center p-ai-center col-12 mb-3 mt-2 " onClick={() => {
                setDeatilComp(item)
            }}>
                <div className='inter'>{item}</div>
                <div className=''>{<Button className='bg-white ' link="true" ><i className="pi pi-angle-down ml-2 reportsColour" ></i></Button>}</div>
            </div>
        </div>
    }

    const Card = (field, dailyTotal, total, totalField, setFunc, collection, activeButton, setButton, head, setHead, borderStyle, classNames) => {
        let chartData = {
            "category": [],
            data: [{
                data:[],
                name:"",
                color: colorMap[field]
            }],

        };
        if (Array.isArray(total)) {
            total.map((item) => {
                if(item.count)chartData.data[0].data.push(Number(convertValueType(item.count)));
                else chartData.data[0].data.push(Number(convertValueType(item.total)));
                if(item.year)chartData["category"].push(item.year)
                else chartData["category"].push(monthMap[item.month])
            })
        }
        let dynamicHead;
        if (field.includes("Revenue")) dynamicHead = headCollection.amountHead;
        else dynamicHead = headCollection.countHead;
        const plazaHeader = (
            <ColumnGroup className="bg-cyan-500 text-white card ">
                <Row>
                    {
                        dynamicHead.map((item) => {
                            return <Column header={item.toUpperCase()} key={item} className={`bg-white `} style={{ marginRight: '110px' }}></Column>
                        })
                    }
                </Row>
            </ColumnGroup>
        );
        const weekMap = {
            "Monday": 1,
            "Tuesday": 2,
            "Wednesday": 3,
            "Thursday": 4,
            "Friday": 5,
            "Saturday": 6,
            "Sunday": 7
        };

        const end = weekMap[moment().format('dddd')];
        const start = end + 35;
        const startDate = moment().subtract(start, "days").format("YYYY-MM-DD");
        const endDate = moment().subtract(end + 1, "days").format("YYYY-MM-DD");

        const monthPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        year: 2024,
                        plazacode: null
                    }
                },
                {
                    $group: {
                        _id: `$month`,
                        total: { $sum: `$${totalMap[collection]}` },
                    }
                },
                {
                    $project: {
                        month: "$_id",
                        total: 1,
                        field: collection,
                        growth: "0"
                    }
                },
                { $sort: { month: 1 } }
            ]
        };

        const yearPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        plazacode: null
                    }
                },
                {
                    $group: {
                        _id: "$year",
                        count: { $sum: `$${totalMap[collection]}` },
                    }
                },
                {
                    $project: {
                        year: "$_id",
                        count: 1,
                        field: collection,
                        growth: "0"
                    }
                },
                { $sort: { year: 1 } }
            ]
        };

        const weeklyPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        year: 2024,
                        date: {
                            $gte: startDate,
                            $lte: endDate
                        },
                        plazacode: null
                    }
                },
                {
                    $group: {
                        _id: "$date",
                        total: { $sum: `$${totalMap[collection]}` }
                    }
                },
                {
                    $project: {
                        total: 1,
                        week: "$_id",
                        field: collection,
                        growth: "0"
                    }
                },
                { $sort: { week: 1 } }
            ]
        };

        if (selectedPlaza) {
            monthPayload.aggregate[0].$match.plazacode = selectedPlaza;
            yearPayload.aggregate[0].$match.plazacode = selectedPlaza;
            weeklyPayload.aggregate[0].$match.plazacode = selectedPlaza;
        }
        else {
            monthPayload.aggregate[0].$match.plazacode = { $in: plazaCodes };
            yearPayload.aggregate[0].$match.plazacode = { $in: plazaCodes };
            weeklyPayload.aggregate[0].$match.plazacode = { $in: plazaCodes };
        }
        if (field === "Total ETC Revenue") {
            monthPayload.aggregate[0].$match["tablename"] = { $in: ["cch1", "cch2", 'pos'] };
            yearPayload.aggregate[0].$match["tablename"] = { $in: ["cch1", "cch2", 'pos'] };
            weeklyPayload.aggregate[0].$match["tablename"] = { $in: ["cch1", "cch2", 'pos'] };
            monthPayload.aggregate[2].$project["type"] = "etc"
            yearPayload.aggregate[2].$project["type"] = "etc"
            weeklyPayload.aggregate[2].$project["type"] = "etc"
        }

        return (
            <div className={`${classNames}`}>
                <div className='card shadow-3' style={{ border: ".0625rem solid #f5f2f2", borderRadius: "0.7rem" }}>
                    <div className="flex new-inter mt-3 justify-content-center align-items-center" style={{color:colorMap[field],borderBottom:`1px solid ${colorMap[field]}`}}>
                        {`${field} Comparison`}
                        {screenWidth < 768 && (
                            <Button className="ml-1 bg-white close-color hover:text-gray-900" onClick={() => { setDeatilComp(null) }}>
                                <i className='pi pi-times text-red'></i>
                            </Button>
                        )}
                    </div>
                    <div className='mt-1 flex flex-column align-items-center p-2'>
                        <div className='flex justify-content-center bg-white mt-3 accordion-type-box'>
                            <Button
                                label='Yearly'
                                style={{backgroundColor:`${activeButton === 'Yearly'?colorMap[field]:"white"}`}}
                                className={activeButton === 'Yearly' ? "button-color border-round-xs " : "button-color border-round-xs  bg-white text-black-alpha-90"}
                                onClick={() => {
                                    setButton('Yearly');
                                    generalFunc(collection, yearPayload, setFunc, setHead, "Year");
                                }}
                            />
                            <Button
                                label='Monthly'
                                style={{backgroundColor:`${activeButton === 'Monthly'?colorMap[field]:"white"}`}}
                                className={activeButton === 'Monthly' ? "button-color border-round-xs " : "button-color border-round-xs bg-white text-black-alpha-90"}
                                onClick={() => {
                                    setButton('Monthly');
                                    generalFunc(collection, monthPayload, setFunc, setHead, "Month");
                                }}
                            />
                            <Button
                                label='Weekly'
                                style={{backgroundColor:`${activeButton === 'Weekly'?colorMap[field]:"white"}`}}
                                className={activeButton === 'Weekly' ? "button-color border-round-xs " : "button-color border-round-xs bg-white text-black-alpha-90"}
                                onClick={() => {
                                    setButton('Weekly');
                                    generalFunc(collection, weeklyPayload, setFunc, setHead, "Week");
                                }}
                            />
                        </div>
                        {!total ?
                            <div className="flex justify-content-center mt-8">
                                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div> : <>
                                <div className="w-9" style={{ overflow: "auto" }}>
                                    <DataTable value={total} responsiveLayout="scroll">
                                        <Column header="YEAR" field="year" className="bg-white" body={(e)=>{return yearShow(e,field)}}></Column>
                                        <Column header={field.includes("Revenue") ? "AMOUNT (₹)" : "VEHICLES"} style={{ maxWidth: "8rem" }} field="count" body={commaAdder} className="bg-white font-medium "></Column>
                                        <Column header="GROWTH" field="growth" className="bg-white font-medium " style={{ color: "#5E6771" }} ></Column>
                                    </DataTable>
                                </div>
                            </>

                        }
                    </div>
                </div>
                <div className='card generalCard' style={{maxHeight:"17rem",minHeight:"17rem"}}>
                <div className='col-12 flex justify-content-between'>
                            <p className='chartHeading' style={{ fontSize: "1rem",color:colorMap[field] }}>{field} Comparison</p>
                        </div>
                    <GeneralBarChart chartData={chartData} selectedvalueType={selectedvalueType} legend={false}/></div>
            </div>
        );
    }

    return <div className=' '>
        <div className=" ">
            <h5 className="mb-0" style={{ color: "#2A2E35" }}>{`👋 Hey ${name}, ${getGreetingMessage()}.`}</h5>
            <div className='my-3'>
                <div className='pt-2 bg-white card generalCard' >
                    <div className="col-12 flex justify-content-start flex-wrap" style={{ gap: "1rem" }}>
                        <div className="lg:flex-grow-0 flex-grow-1" style={{ minWidth: "14rem" }}>
                            <div className='w-full'>
                                <label className="relabel" >Type:</label>
                                <Dropdown options={valueTypes.current} value={selectedvalueType} onChange={(e) => setSelectedValueType(e.value)} optionLabel='type' optionValue='value' className="general-bar-chart-filter flex align-items-center " style={{ borderRadius: '20px', border: '2px solid #0C6291', width: "100%" }}></Dropdown>
                            </div>
                        </div>
                        <div className="lg:flex-grow-0 flex-grow-1" style={{ minWidth: "14rem" }}>
                            {SPVSelector()}
                        </div>
                        {spv && <div className="lg:flex-grow-0 flex-grow-1" style={{ minWidth: "14rem" }}>
                            {TollSelector()}
                        </div>}
                        <div className="lg:flex-grow-0 flex-grow-1" style={{ minWidth: "14rem" }}>
                            {StyledCalendar()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='grid justify-content-between'>
            {yesterdayCard("Total Traffic", "borderStyle1", "traffic", yestraffic, "", "col-12 lg:col-4")}
            {yesterdayCard("Total Revenue", "borderStyle2", "Amount", yesrev, "", "col-12 lg:col-4")}
            {yesterdayCard("Total ETC Revenue", "borderStyle3", "Amount", yesRevLoss, "", "col-12 lg:col-4")}

            {/* </div>
            <div className='col-12 lg:col-6 p-0 flex justify-content-around'> */}
            {/* {yesterdayCard("Total Exempted", "borderStyle4", "Number of Vehicles", yesExempt, "")} */}
            {/* </div> */}
        </div>
        <div className='col-12 mt-2'>
            <div className='col-12 grid justify-content-around p-0'>
                {screenWidth > 768 || detailcomp === "Total Traffic Comparison" ? Card("Total Traffic", yestraffic, traffic, "traffic", setTraffic, "nq-total_traffic_daily", trafficb, setTrafficB, trtablHeader, setTRTableHead, "borderStyle1", "col-12 lg:col-4") : reportShow("Total Traffic Comparison")}
                {screenWidth < 768 && field === "nq-total_traffic_daily" && MonthlyDetails("ml-6")}
                {screenWidth > 768 || detailcomp === "Total Revenue Comparison" ? Card("Total Revenue", yesrev, revenue, "Amount", setRevenue, "nq-totalRevenue_by_plaza_date_daily", revb, setRevB, retablHeader, setReTableHead, "borderStyle2", "col-12 lg:col-4") : reportShow("Total Revenue Comparison")}
                {screenWidth < 768 && field === "nq-totalRevenue_by_plaza_date_daily" && MonthlyDetails("ml-6")}
                {screenWidth > 768 || detailcomp === "Total ETC Revenue Comparison" ? Card("Total ETC Revenue", yesRevLoss, loss, "Amount", setLoss, "nq-totalRevenue_by_plaza_date_daily", lossb, setLossB, votablHeader, setVoTableHead, "borderStyle3", "col-12 lg:col-4") : reportShow("Total ETC Revenue Comparison")}
                {screenWidth < 768 && field === "nq-totalRevenue_by_plaza_date_daily" && MonthlyDetails("ml-6")}
                {/* {screenWidth > 768 || detailcomp === "Total Exempted Comparison" ? Card("Total Exempted", yesExempt, exempt, "Number of Vehicles", setExempt, "nq-nontollable_vrn_cch_daily", exemptb, setExemptB, extablHeader, setExTableHead, "borderStyle4") : reportShow("Total Exempted Comparison")}
                {screenWidth < 768 && field === "nq-nontollable_vrn_cch_daily" && MonthlyDetails("ml-6")} */}
            </div>
            <div>{screenWidth > 768 && MonthlyDetails("ml-3")}</div>
        </div>
        <TollRevenueReport heading={"Daily Toll Revenue Collection Report"}/>
        <TollRevenueReport type={"Traffic"} count={"traffic"}  heading={"Daily Traffic Report"}/>
        <ExemptionReports  heading={"Daily Exempt + Not paid Traffic"}/>
    </div>
}

export default PlazaDashBoard