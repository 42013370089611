import React, { useState, useEffect, useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { ProgressSpinner } from "primereact/progressspinner";

const GeneralBarChart = React.memo(({ chartData,selectedvalueType ,legend,height}) => {
    const [state, setState] = useState(null);
    const barChartOptions = useMemo(() => {
        if (!chartData || !chartData.data || !chartData.data.length) {
            console.error("Invalid chartData format:", chartData);
            return {};
        }

        return {
            chart: {
                type: "column",
                height:height ?? 180,
            },
            title: {
                text: "",
                align: "center",
                style: {
                    fontSize: "16px",
                    fontWeight: "bold",
                },
            },
            xAxis: {
                categories: chartData.category || [],
                title: {
                    text: chartData.text || "",
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: "",
                },
            },
            plotOptions: {
                series: {
                    borderRadius: 5,
                    dataLabels: {
                        enabled: true,
                        format: `{y}${selectedvalueType?.["abbr"] || ""}`,
                        style: {
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#000",
                        },
                    },
                },
            },
            legend: {
                align: "center",
                verticalAlign: "top",
                layout: "horizontal",
                itemStyle: {
                    fontSize: "12px",
                },
            },
            series: chartData.data.map((item)=>{
                return{
                    showInLegend: legend??false,
                    ...item,
                }
            })
           
        };
    }, [chartData,selectedvalueType]);

    useEffect(() => {
        setState(barChartOptions);
    }, [barChartOptions]);

    if (!state) {
        return  <ProgressSpinner
        className="col-12 grid justify-content-center align-items-center mt-5 ml-5"
        style={{ width: "50px", height: "50px" }}
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
    />
    }

    return <HighchartsReact highcharts={Highcharts} options={state} />;
});

export default GeneralBarChart;
