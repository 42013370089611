import React, { useState, useEffect, useMemo } from "react";
import { Button } from "primereact/button";
import { read, utils } from "xlsx";
import TowerService from "../../service/tower";
import CountWidgets from "./countWidgets";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
const UploadSites = () => {
    const serviceId = localStorage.getItem("serviceId");
    const [fileName, setFileName] = useState(null);
    const [file, setFile] = useState(null);
    const [disableBtn, setBtnDisabled] = useState(true);
    const [infoMsg, setInfoMsg] = useState("");
    const [report, setReport] = useState([]);
    const uid = localStorage.getItem("uid");
    const isAdmin = useMemo(()=> ["UserManagement.WRITE","UserManagement.READ"].every((p)=>JSON.parse(localStorage.getItem("permissions").includes(p))), [])
    const okStatus = (row) => {
        if (row.status) return "pending"
        if (row.Okay) return row.Okay.count
    }
    useEffect(() => {
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        ...(!isAdmin && {uid : uid}),
                        totalCount: {
                            $gt: 0
                        }

                    },
                },
                {
                    $project: {
                        totalCount: 1, uploadingTime: 1, "Okay.count": 1
                    }
                },

                { $sort: { uploadingTime: -1 } }

            ],
        };
        Promise.all([TowerService.getUploadReport(payload, serviceId)]).then((res) => {
            setReport(res[0]);
        })

    }, [])

    const handleSubmit = async () => {
        setBtnDisabled(true);
        const formData = new FormData();
        const extension = fileName.split(".").pop();
        formData.append("serviceId", serviceId);
        formData.append("uid", uid);
        if (extension === "csv") {
            formData.append("file", file);
        } else {
            const workbook = read(await file.arrayBuffer());
            const csv = utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
            const data = new Blob([csv], { type: "text/csv" });
            formData.append("file", data, "site_upload" + Date.now() + ".csv");
        }
        let uploadRes = await TowerService.uploadSitesAPI(formData);
        if (uploadRes.status === 200) {
            let newTemp = {
                uploadingTime: moment(Date.now()).format("YYYY-MM-DD HH:mm"),
                totalCount: "pending",
                status: "pending"
            }
            let tempReport = [...report, newTemp];
            setReport(tempReport);
            setInfoMsg("File uploaded successfully. Visit after sometime to check the status");
        } else {
            setInfoMsg("Something went wrong");
        }
    };

    const handleFile = (e) => {
        if (e.target.files && e.target.files.length) {
            setInfoMsg("");
            setBtnDisabled(false);
            setFileName(e.target.files[0].name);
            setFile(e.target.files[0]);
        }
    };

    const timeTemplate = (data) => {
        return moment(data.uploadingTime).format("Do MMM, YY HH:mm:ss");
    };

    return (
        <div className="card" style={{ height: "100%" }}>
            <CountWidgets />
            <br />
            <div className="grid">
                <div className=" col-12 lg:col-6 flex flex-column  h-13rem mt-3 mx-auto justify-content-center align-items-center">
                    <div className="flex m-3 flex-column align-items-center border-1 border-dashed border-400 border-round-md" style={{ padding: "1rem" }}>
                        <div className="fileUpload">
                            <div className="text-6xl">
                                <i className="pi pi-upload" style={{ fontSize: "2rem", color: "#009688" }}></i>
                            </div>
                            <span>{fileName}</span>
                            <span className="mt-3 mb-3 block">Upload Sites</span>
                            <input type="file" className="upload" onChange={(e) => handleFile(e)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        </div>
                        {<Button label="Upload" onClick={handleSubmit} disabled={disableBtn} />}
                        {infoMsg ? (
                            <>
                                <br />
                                <span className="color2">{infoMsg}</span>
                            </>
                        ) : null}
                        <br />
                        <a href={process.env.REACT_APP_IMAGE_STATIC_URL + "/atcFileUploadFormat/format.csv"} download>
                            Template for site upload
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <DataTable value={report} rows={10} responsiveLayout="scroll" paginator className="col-12">
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Uploaded Time" style={{ width: "100px" }} body={timeTemplate}></Column>
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Total Count" style={{ width: "100px" }} field={"totalCount"}></Column>
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Sucess Count" style={{ width: "100px" }} body={okStatus}></Column>
                </DataTable>
            </div>
        </div>
    );
};

export default UploadSites;
