import React, { useEffect, useRef, useState } from 'react'
import FileListComp from '../../components/FileListComp';
import apiService from "../../service/apiService"
import { Button } from 'primereact/button'
import { httpConstants } from '../../lib/constants';
import moment from 'moment';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect'
import { AssignmentStatusPage } from './AssignmentStatusPage';
import { dateFormatter } from '../../utilities/utillFunction';
import { Toast } from "primereact/toast"
import { Tooltip } from 'primereact/tooltip'
import { color } from 'highcharts';
import { mailSendingFunction } from './AssignmentStatusPage';
import { mailType } from '../../utilities/constant';

const ProjectList = () => {

    const emailId = localStorage.getItem("emailId")
    const [recentProjects, setRecentProjects] = useState({})
    const [pastProjects, setPastProjects] = useState({})
    const serviceId = localStorage.getItem("serviceId")
    const [allStatus, setAllStatus] = useState([])
    const [projectName,setProjectName] = useState("")
    const [createProjectModal, setCPModal] = useState(false)
    const [changeStatusModal, setCSModal] = useState(false)
    const projToChangeStatus = useRef("")
    const vendorsPTypemaster = useRef({})
    const toast = useRef()
    const clickedIsCompleted = useRef(false)


    useEffect(() => {
        const payload = {
            "serviceId": serviceId,
            "userEmail": emailId

        }
        const url = process.env.REACT_APP_API_PARSER_URL + "/fileSharingUtility/listProjects/"
        apiService.httpService(httpConstants.METHOD_TYPE.POST, payload, url).then((res) => {
            if (res.Projects && res.Projects.length) {
                let pastProjectsMap = {}
                let recentProjectsMap = {}
                res.Projects.forEach(item => {
                    // if the date is greadter than threshold date then it will go in past map
                    let threshold_date = moment().subtract(15, "days").format("YYYY-MM-DD HH:mm:ss")
                    let project_date = dateFormatter(item.modified_ts, "YYYY-MM-DD HH:mm:ss")
                    if(project_date < threshold_date){
                        pastProjectsMap[item["projectName"]] = item
                    }
                    else recentProjectsMap[item["projectName"]] = item
                })
                setRecentProjects(recentProjectsMap)
                setPastProjects(pastProjectsMap)
            }
        })
    }, [createProjectModal])

    const showNames = (namesInArray) => {
        let names = ""
        if(namesInArray && namesInArray.length){
            namesInArray.forEach(item => {
                if(names && names !== ""){
                    names = ", " + item
                 }
                 else names  = item
            })
        }
        return names ?? "--"
    }
    const dataviewGridItem = (data) => {
        let isCompleted = false
        if (data.status && data.status.toLowerCase() === "completed") isCompleted = true
        return (
            <div className="col-12 md:col-4">
                <Tooltip target=".custom-target-icon" style={{backgroundColor:"white",color:"blue"}}/>
                <div className={`card m-3 cursor-pointer ${isCompleted && "shadow-4"}`} style={{ "borderRadius": "1rem", ...(isCompleted ? { "opacity": 0.65} : {boxShadow : "0px 0px 9px 6px #C8E6C9"}) }}  onClick={(e)=>{
                    clickedIsCompleted.current = isCompleted
                    e.stopPropagation()
                    setProjectName(data.projectName)}}>
                    <div className="flex align-items-center justify-content-between">
                        <span className={`product-badge status-instock`}>{data.status ? data.status.toUpperCase() : "--"}</span>
                        <Button id={data.projectName} type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain" onClick={(e) => {
                             e.stopPropagation()
                             if(isCompleted) return
                            onChangeStatusClick(e)}}></Button>
                    </div>
                    <div className="text-center">
                        <p className="text-2xl font-bold m-0" style={{ wordBreak: "break-all"}}>{data.projectName ?? "--"}</p>
                        <p className='m-1' style={{ wordBreak: "break-all", color: "#938F90" }}>{data.projectType ?? "--"}</p>
                        <div className='w-full flex justify-content-center align-items-center p-3' style={{ backgroundColor: "#F8F9FD", borderRadius: "1rem", color: "#938F90", wordBreak: "break-all" }}>    
                            <div className=''>
                                <p className='m-0 font-bold text-600'>{data?.userGroups?.length && showNames(data.userGroups)}</p>
                                <div className=' flex justify-content-center align-items-center'>
                                    <i className='pi pi-user mr-3'></i>
                                    <p className='m-0'>{(data?.POCids && data.POCids.length) && showNames(data.POCids)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='w-full'></hr>
                    <div className='flex justify-content-between align-items-center' style={{ color: "#938F90" }}>
                    <div className=' custom-target-icon' data-pr-tooltip="Created On"><i className='pi pi-calendar-plus mr-1'></i><span>{dateFormatter(data.Created_ts, "DD MMM YY HH:mm")}</span></div>
                    <div className=' custom-target-icon' data-pr-tooltip="Last Submission"><i className='pi pi-calendar-times pi-pencil mr-1'></i><span>{dateFormatter(data.modified_ts, "DD MMM YY HH:mm")}</span></div>
                    </div>
                </div>
            </div>
        );
    }
    const onChangeStatusClick = async (e) => {
        const projectname = e.currentTarget.id
        projToChangeStatus.current = projectname
        if (!allStatus.length) {
            const payload = {
                filter: { type: "Project" },
                projection: { "_id": 0 }
            }
            let res = await apiService.httpDBService(payload, serviceId, "nq-statuses")
            const statuses = []
            if (res && res.length) {
                res[0]["statuses"].forEach(item => {
                    statuses.push({
                        label: item,
                        value: item
                    })
                })
                if (statuses.length) {
                    setAllStatus(statuses)
                    setCSModal(true)
                }
            }
        }
        else setCSModal(true)
    }
    const dvheader = (
        <Button icon="pi pi-plus" className="p-button-rounded p-button-info p-button-outlined" onClick={() => onCreateProjectClick()} />
    )
    //use usecallback here
    const addOrChangeProject = async (e, type, info) => {
        switch (type) {
            case "add":
                info = JSON.parse(JSON.stringify(info))
                info["userGroups"] = info["groupNames"] && info["groupNames"].length ? [info["groupNames"][0].group] : null
                info["POCids"] = info["POCids"] ? [info["POCids"]] : null
                info["status"] = "Active"
                let res = await callApi(info)
                return res
                break;
            case "change_status":
                let projectDetails
                if(info["projectName"] in recentProjects) projectDetails = recentProjects[info["projectName"]]
                else projectDetails = pastProjects[info["projectName"]]
                projectDetails["status"] = info["status"]
                let change_res = await callApi(projectDetails, true)
                return change_res
        }
        async function callApi(projDetails, isEdit = false) {
            const response = {
                "success" : false,
                "message" : ""
            }
            const payload = {
                "serviceId": serviceId,
                "userEmail": emailId,
                "projectName": projDetails.projectName?.trim(),
                "projectType": projDetails.projectType,
                "groupNames": projDetails.userGroups,
                "POCids": projDetails.POCids,
                "edit": isEdit,
                "status": projDetails["status"]
            }
            for(const key in projDetails){
                if(projDetails[key] === "" || projDetails[key] === null){
                    response["message"] = "Please Select all the fields"
                    return response
                }
            }
            const url = process.env.REACT_APP_API_PARSER_URL + "/fileSharingUtility/projectAddEdit/"
            await apiService.httpService(httpConstants.METHOD_TYPE.POST, payload, url).then((res) => {
                if (res.message) {
                    response["success"] = true
                    response["message"] = res.message
                    if(type==="add")mailSendingFunction(mailType.PROJECT_CREATION,payload)
                }
            }).catch(err=>{
                if(err.response?.data){
                    if(typeof(err.response.data) === "object"){
                        response["message"] = err.response.data.detail
                    }
                    else response['message'] = err.response.statusText
                }
            })
            return response
        }
    }

    const onCreateProjectClick = async () => {
        if (!("projectType" in vendorsPTypemaster.current) || !("vendors" in vendorsPTypemaster.current)) {
            const vendormasterPayload = {
                projection: {
                    "_id": 0,
                    "group": 1,
                    "users": 1
                }
            }
            const projectTypeMasterPayload = {
                projection: {
                    "_id": 0,
                    "name": 1,
                }
            }
            let res = await Promise.all([apiService.httpDBService(vendormasterPayload, serviceId, "nq-user-groups"), apiService.httpDBService(projectTypeMasterPayload, "nextqore", "nq-file-utility-project-types")])
            const vendorprojTypeMap = {}
            if (res && res.length) {
                if (res[0].length) vendorprojTypeMap["vendors"] = res[0]
                if (res[1].length) vendorprojTypeMap["projectType"] = res[1]
                if (("projectType" in vendorprojTypeMap) && ("vendors" in vendorprojTypeMap)) {
                    vendorsPTypemaster.current = vendorprojTypeMap
                    setCPModal(true)
                }
            }
        }
        else setCPModal(true)
    }

    if(projectName.length)return <AssignmentStatusPage project={projectName} isCompleted={clickedIsCompleted.current} Func={setProjectName}/>

    return (
        <>
            <FileListComp header={emailId === "support@nextqore.com" ? dvheader : null} id={"fu_list_recent_projects"} headerText="Recent Projects" defLayout="grid" switchLayout={false} emptyMessage="No Projects Found" dataviewGridItem={dataviewGridItem} dataviewValue={Object.values(recentProjects)} />
            <Toast ref={toast} />
            <br></br>
            <FileListComp id={"fu_list_past_projects"} headerText="Past Projects" defLayout="grid" switchLayout={false} emptyMessage="No Projects Found" dataviewGridItem={dataviewGridItem} dataviewValue={Object.values(pastProjects)} />
            <ChangeStatusModal showModal={changeStatusModal} toast={toast} setShowModal={setCSModal} statuses={allStatus} pName={projToChangeStatus.current} changeStatus={addOrChangeProject} />
            <CreateProjectForm showModal={createProjectModal} toast={toast}  setShowModal={setCPModal} addProject={addOrChangeProject} master={vendorsPTypemaster.current} />
        </>
    )
}

const ChangeStatusModal = ({ showModal, setShowModal, statuses = [], pName = "", changeStatus, toast }) => {
    const [status, setStatus] = useState("")
    const [errMsg, setErrMsg] = useState("")
    const handleChangeStatusClick = async (e) =>{
        let res = await changeStatus(e, "change_status", { projectName: pName, status : status})
        if(!res.success){
            setErrMsg(res.message)
        }
        else{
            mailSendingFunction(mailType.PROJECT_COMPLETED,{ projectName: pName, status : status,serviceId:localStorage.getItem("serviceId"),"userEmail":localStorage.getItem("emailId")})
            toast.current.show({ severity: 'success', summary: 'Change Status Complete', detail: res.message ?? "" })
            setStatus("")
            setErrMsg("")
        }
    }
    return (
        <Dialog visible={showModal} header={`Change Status for ${pName}`} style={{ borderRadius: "1rem" }} className='lg:w-4 w-10' modal onHide={() => {setShowModal(false); setErrMsg("");setStatus("")}}>
            <Dropdown className='w-full mb-3 border-400' value={status} onChange={(e) => setStatus(e.value)} options={statuses} placeholder='Select Status' />
            {errMsg && <p className='text-base text-center' style={{color:"red"}}>{errMsg}</p>}
            <Button label='Change' style={{ float: "right" }} onClick={(e) => handleChangeStatusClick(e)}></Button>
        </Dialog>
    )
}

const CreateProjectForm = ({ showModal, setShowModal, changeStatus, master = {}, addProject, toast }) => {
    const [projectInfo, setProjectInfo] = useState({})
    const [pocs, setPocs] = useState([])
    const [errMsg, setErrMsg] = useState("")

    const handleAddProjClick = async (e) => {

        let res = await addProject(e, "add", projectInfo)
        if(!res.success){
            setErrMsg(res.message)
        }
        else {
            toast.current.show({ severity: 'success', summary: 'Add new Project', detail: res.message ?? "" })
            setProjectInfo({})
            setPocs([])
            setErrMsg("")
        }
    }
    const handleOnChange = (e) => {
        if (e.target.name === "groupNames") {
            let vendor = e.value
            let temp = {[e.target.name]: e.value, "POCids" : ""}
            if (vendor.length && vendor[0].users && vendor[0].users.length) {
                const initialPoc = vendor[0].users.find(user => user.isPOC === true)
                setPocs(vendor[0].users)
                temp["POCids"] = initialPoc.email
            }
            setProjectInfo({ ...projectInfo, ...temp })
        }
        else {
            setProjectInfo({ ...projectInfo, [e.target.name]: e.value ? e.value : e.target.value })
        }
    }
    const header = (
        <p className='text-3xl font-bold text-center'>Add New Project</p>
    )
    return (
        <Dialog blockScroll header={header} visible={showModal} onHide={() => { setShowModal(false); setProjectInfo({}); setErrMsg(""); setPocs([]) }} className='lg:w-4 w-10' modal>
            <div className='w-full'>
                <label htmlFor="projname" className='text-lg block font-bold mb-1'> Project Name </label>
                <InputText className='mb-2 w-full input-border' name="projectName" value={projectInfo["projectName"] ?? ""} onChange={(e) => handleOnChange(e)} id="projname" />

                <label htmlFor="projtype" className='text-lg block font-bold mb-1'>Work Category</label>
                <Dropdown id="projtype" name="projectType" value={projectInfo["projectType"] ?? ""} options={master["projectType"] ?? []} onChange={(e) => handleOnChange(e)} optionLabel='name' optionValue='name' className='w-full mb-2 input-border' />

                <label htmlFor="vendors" className='text-lg block font-bold mb-1'> Work Partner</label>
                <MultiSelect name="groupNames" value={projectInfo["groupNames"] ?? []} selectionLimit={1} options={master["vendors"] ?? []} onChange={(e) => handleOnChange(e)} display="chip" optionLabel="group" placeholder="Select Vendors" filter className="bg-white mb-2 input-border w-full" />

                <label htmlFor="poc" className='text-lg block font-bold mb-1'>Partner's Point of Contact</label>
                <Dropdown id="poc" name='POCids' value={projectInfo["POCids"]} onChange={(e) => handleOnChange(e)} options={pocs} optionLabel="name" optionValue='email' className='w-full mb-3 input-border' />

                {errMsg && <p className='text-base text-center' style={{color:"red"}}>{errMsg}</p>}

                <Button label='Add Project' style={{ float: "right",color:"#0C6291 !important" }} onClick={(e) => handleAddProjClick(e)}></Button>
            </div>
        </Dialog>
    )
}

export default ProjectList